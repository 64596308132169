<template>
  <div>
    <h1 class="title">Você não tem crédito suficiente</h1>
    <span>Pacotes: {{ codigo }}</span>
    <span>Total: $ {{ (total * 1).toFixed(2) }}</span>
    <span class="msg">Caro Cliente,</span>
    <span class="msg"
      >Você selecionou dois ou mais pacotes e não tem crédito suficiente para pagar a todos, selecione apenas os pacotes que são possíveis de se pagar com os créditos disponíveis ou solicite o
      valor necessário que totalize os créditos suficientes, via Zelle, para a o email <b>xprex@xprexbox.com</b>, para liberar a emissão de suas etiquetas dos Correios</span
    >
    <div @click="close" class="btn-ok">ok</div>
  </div>
</template>

<style lang="stylus" scoped>

h1
span
    text-align center
    font-weight bold

h1
    color #4CB6D2
    margin 8px 0 24px 0

span
    font-size 1.6em
    display block

.msg
    text-align left
    padding 8px 16px
    font-weight normal
    font-size 0.8em

.btn-ok
    background-color #4472C4
    color white
    text-align center
    padding 12px 48px
    border-radius 12px
    transition 0.3s
    width: 27%
    display block
    margin 16px auto 0
    cursor pointer

    &:hover
        color white
        background-color darken(#4472C4, 20%)
</style>

<script>
export default {
  props: ["codigo", "total"],
  methods: {
    close() {
      this.$bvModal.hide("remessa-limite");
    },
  },
};
</script>
