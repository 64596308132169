import Vue from "vue";
import Vuex from "vuex";
import api from "@/services";

// Modules
import Home from "./modules/home";
import Senders from "./modules/senders";
import Receivers from "./modules/receivers";
import Itens from "./modules/itens";
import Packets from "./modules/packets";
import PacketsPaid from "./modules/packets_paid";
import PacketsToPay from "./modules/packets_topay";
import Redirectors from "./modules/redirectors";
import Extrafield from "./modules/extrafield";
import Conferencia from "./modules/conferencia";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || "{}"),
    token: localStorage.token || undefined,
    selected_infos: JSON.parse(localStorage.selected_infos || "{}"),
    packageSave: {}, // Fluxo de criar/editar pacote
    dashboard: [
      {
        key: "redirectors",
        icon: { key: "computer", custom: false },
        title: "Redirecionadores",
        layout: "table",
        only: ["Super Admin", "RedirectorPremium"],
      },
      {
        key: "senders",
        icon: { key: "sender", custom: true },
        title: "Remetentes",
        layout: "table",
      },
      {
        key: "receivers",
        icon: { key: "receiver", custom: true },
        title: "Destinatários",
        layout: "table",
        async: true
      },
      /*{
        key: "boxs",
        icon: { key: "box", custom: true },
        title: "Embalagens",
        layout: "table",
      },*/
      {
        key: "itens",
        icon: { key: "search", custom: true },
        title: "Itens",
        layout: "table",
      },
      {
        key: "packets",
        icon: { key: "packet", custom: true },
        title: "Pacotes Enviados",
        layout: "table",
        sendTo: "Pacotes Pagos",
        async: true
      },
      {
        title: "Pacotes Pagos",
        key: "packets_paid",
        layout: "table",
        noMenu: true,
      },
      {
        title: "Pacotes a Pagar",
        key: "packets_topay",
        layout: "table",
        noMenu: true,
      },
      {
        key: "extrafield",
        noMenu: true,
        noRoute: true,
      },
      {
        title: "Conferência",
        key: "conferencia",
        layout: "table",
        icon: { key: "fact_check", custom: false },
      }
    ],
  },
  getters: {
    userName: (state) => {
      return state.user.name === undefined ? "" : state.user.name;
    },
    isLogged: (state) =>
      Object.keys(state.user).length !== 0 && state.token !== undefined,

    isAdmin: (state) =>
      state.user.groups.reduce((v, g) => v || g.key === "Super Admin", false),

    isRedirector: (state) =>
      state.user.groups.reduce((v, g) => v || g.key === "Redirector", false),

    isRedirectorPremium: (state) =>
      state.user.groups.reduce(
        (v, g) => v || g.key === "RedirectorPremium",
        false
      ),
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    logout: (state) => {
      state.user = {};
      state.token = undefined;
    },
    selected_infos: (state, payload) => {
      state.selected_infos = payload;
    },
    packageSave: (state, payload) => {
      state.packageSave = payload;
    },
    updateCredito: (state, payload) => {
      state.user.credito_disponivel = payload.disponivel;
      state.user.credito_utilizado = payload.utilizado;
      localStorage.user = JSON.stringify(state.user)
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user);
      localStorage.token = payload.token;
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
      context.commit("login", payload);
    },
    logout: (context) => {
      localStorage.clear();
      delete api.defaults.headers.common.Authorization;
      context.commit("logout");
    },
    selected_infos: (context, payload) => {
      localStorage.selected_infos = JSON.stringify(payload);
      context.commit("selected_infos", payload);
    },
    packageSave: (context, payload) => {
      context.commit("packageSave", payload);
    },
    updateCredito: (context, payload) => {
      context.commit("updateCredito", payload);
    }
  },
  modules: {
    senders: Senders,
    receivers: Receivers,
    redirectors: Redirectors,
    //boxs: Boxs,
    itens: Itens,
    packets: Packets,
    packets_paid: PacketsPaid,
    packets_topay: PacketsToPay,
    home: Home,
    extrafield: Extrafield,
    conferencia: Conferencia
  },
});
