<template>
  <div>
    <Interface
      :title="state.title"
      btnLabel="Salvar"
      @action="onSubmit"
      noHeader
      separator
      type="submit"
      variant="primary"
      btnAsEvent
      :hasBtn="currentRouteName === 'redirectors.redirector'"
    >
      <b-form @submit="onSubmit" ref="form">
        <b-row>
          <b-col
            v-for="field in editableFields"
            :key="field.key"
            :cols="field.col"
          >
            <b-form-group :id="'input-add-group-' + field.key">
              <div class="h-separator">
                <label :for="'input-add-' + field.key" class="title"
                  >{{ field.label
                  }}{{ field.label === "Complemento" ? "" : " *" }}</label
                >
                <span></span>
              </div>
              <b-form-input
                v-if="['text', 'email', 'number'].includes(field.type)"
                :id="'input-add-' + field.key"
                v-model="form[field.key]"
                :type="field.type"
                :min="field.min"
                v-maska="field.mask"
                :placeholder="
                  field.placeholder ? field.placeholder : field.label
                "
                :required="field.key !== 'complemento'"
              ></b-form-input>
              <b-form-select
                v-if="field.type === 'select'"
                :type="field.type"
                :id="'input-add-' + field.key"
                :placeholder="field.key"
                v-model="form[field.key]"
                :options="field.options"
                required
              >
                <template #first>
                  <b-form-select-option :value="undefined" disabled
                    >País de origem</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <div class="h-separator">
                <label for="password" class="title">Senha *</label>
                <span></span>
              </div>
              <PasswordGenerator @input="updatePassword" auto="true" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </Interface>
  </div>
</template>

<script>
import Interface from "@/components/Dashboard/Interface";
import PasswordGenerator from "@/components/Shared/PasswordGenerator";
import api from "@/services";
import { toast } from "@/utils";
import validateDoc from "@/validate";

export default {
  components: {
    Interface,
    PasswordGenerator,
  },
  data: () => ({
    form: {},
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    state() {
      let path = this.module.split(".");
      let state = this.$store.state;

      path.forEach((e) => {
        state = state[e];
      });

      return state;
    },
    title() {
      return this.state.title;
    },

    fields() {
      return this.state.forms[this.routeKey];
    },

    editableFields() {
      return [
        ...this.fields.filter((f) => f.type !== "hidden"),
        ...(this.$store.state.extrafield.groups["redirector"] || []).map(
          (e) => ({
            // format to table field
            key: e.key,
            label: e.description,
            type: e.children.length ? "select" : "text",
            options: e.children.map((v) => ({
              text: v.description,
              value: v.description,
            })),
            col: e.col,
          })
        ),
      ];
    },
    linkTo() {
      return this.state.linkTo;
    },
  },

  props: {
    itemId: {
      required: false,
      type: String,
    },
    routeKey: {
      // refers to api route, not vue router
      required: true,
      type: String,
    },
    module: {
      required: true,
      type: String,
    },
  },
  methods: {
    updatePassword(e) {
      this.form.password = e;
      this.form.password_confirmation = e;
    },
    onSubmit(event) {
      event.preventDefault();

      if (!validateDoc(this.form?.cpf)) {
        toast(this, "info", "CPF/CNPJ inválido", "o CPF ou CNPJ não é válido");
        return;
      }

      api.post("auth/create", { ...this.form, group_key: "Redirector" }).then(
        (response) => {
          // console.log(response);
          if (response.status === 200) {
            //console.log(response.data.user);
            this.$store.dispatch("redirectors/add", response.data.user);

            toast(
              this,
              "success",
              "Redirecionador registrado",
              "Usuário foi registrado com sucesso"
            );
            this.$router.replace({ path: "/redirectors" });
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Usuário não pode ser registrado",
            error.message +
              (error.response.status === 422
                ? " => " + error.response.data.errors
                : "")
          );
          return Promise.reject(error);
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../style/main/colors.styl'

.h-separator

    display flex

    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary

    span
        align-self center

    span:last-child
        border 1px dashed primary
        height 1px

    span:last-child
        flex-grow 1
.btn
  justify-content space-between
  margin-left 10px
  float left

.btn-service
  float left
  margin-left 10px
  border-radius 50%
  margin-top 20px

.service
  width 60%
  float right
</style>
