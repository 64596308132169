<template>
  <Builder :values="values" v-slot="props" @add="add">
    <b-row>
      <!-- Tipo -->
      <b-col :cols="isTo(props.value) ? 2 : 3">
        <b-form-group>
          <div class="h-separator">
            <label for="input-add-product" class="title"
              >{{ type.label }} *</label
            >
            <span></span>
          </div>

          <b-form-select
            :options="field"
            :value="get(props.value, type.key)"
            @change="input($event, type.key, props.index)"
            required
          >
            <template #first>
              <b-form-select-option :value="undefined" disabled
                >Tipo de faixa</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- Mínimo -->
      <b-col cols="3" v-if="isTo(props.value)">
        <b-form-group>
          <div class="h-separator">
            <label for="input-add-product" class="title"
              >{{ min.label }} *</label
            >
            <span></span>
          </div>

          <b-form-input
            :value="get(props.value, min.key)"
            @input="input($event, min.key, props.index)"
            placeholder="Mínimo"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Máximo -->
      <b-col
        v-if="!isPerfumeOrBattery(props.value)"
        :cols="isTo(props.value) ? 3 : 4"
      >
        <b-form-group>
          <div class="h-separator">
            <label for="input-add-product" class="title"
              >{{ max.label }} *</label
            >
            <span></span>
          </div>

          <b-form-input
            :value="get(props.value, max.key)"
            @input="input($event, max.key, props.index)"
            placeholder="Máximo"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Valor -->
      <b-col
        :cols="isPerfumeOrBattery(props.value) ? 8 : isTo(props.value) ? 3 : 4"
      >
        <b-form-group>
          <div class="h-separator">
            <label for="input-add-product" class="title">{{ v.label }} *</label>
            <span></span>
          </div>

          <b-form-input
            :value="get(props.value, v.key)"
            @input="input($event, v.key, props.index)"
            placeholder="Valor"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="1" class="d-flex align-items-center">
        <div
          class="button mx-2"
          style="background-color: #bd1b24"
          @click="del(props.index)"
        ></div>
      </b-col>
    </b-row>
  </Builder>
</template>

<script>
import Builder from "./Builder";
import Behavior from "./Behavior";

export default {
  components: { Builder },
  mixins: [Behavior],
  data: () => ({
    field: [
      { value: "to", text: "to" },
      { value: "adicional", text: "adicional" },
      { value: "maior que", text: "maior que" },
      { value: "menor que", text: "menor que" },
      { value: "perfumes", text: "perfumes" },
      { value: "lithium_battery", text: "bateria de lítio" },
      { value: "security", text: "seguro" },
    ],
  }),
  computed: {
    type() {
      return this.fields[0];
    },
    min() {
      return this.fields[1];
    },
    max() {
      return this.fields[2];
    },
    v() {
      return this.fields[3];
    },
  },
  methods: {
    isSecurity(o){
      return (this.get(o, this.type.key) || "") === "security"
    },
    isTo(o) {
      return (this.get(o, this.type.key) || "to") === "to";
    },
    isPerfumeOrBattery(o) {
      return (
        (this.get(o, this.type.key) || "") === "perfumes" ||
        (this.get(o, this.type.key) || "") === "lithium_battery" ||
        (this.get(o, this.type.key) || "") === "security"
      );
    },
  },
};
</script>
