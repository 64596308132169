export const toast = (vue, variant, title, msg, extraMsg, delay = 5000) => {
  vue.$bvToast.toast(
    extraMsg
      ? [
          msg,
          vue.$createElement(
            "b-button",
            {
              on: {
                click: () =>
                  vue.$bvToast.toast(extraMsg, {
                    title: "Log",
                    variant: "info",
                    solid: true,
                    toaster: "b-toaster-top-center",
                    noAutoHide: true
                  }),
              },
            },
            "Ver mais"
          )
        ]
      : msg,
    {
      title: title,
      variant: variant,
      solid: true,
      autoHideDelay: delay,
      toastClass: ["toast-btn"]
    }
  );
};

// Convert data to array options for a select field
export const toOptions = (data, key) => {
  const options = [];
  data.forEach((element) => {
    options.push({
      value: element[key.value],
      text: element[key.text],
    });
  });
  return options;
};

// Format Date
export const dateFormat = (date) => {
  return new Date(new Date(date) - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .replace(/-/g, "/")
    .replace("T", " - ")
    .replace("Z", "")
    .slice(0, -4);
};

// String UpperCase
export const upperCase = (string) => {
  return (string || '').toUpperCase();
};

// String UpperCase
export const captalize = (string) => {
  return (string || '').charAt(0).toUpperCase() + (string || '').slice(1);
};

////////////////////////////////////////////
// Base Mutations for modules

export const defaultMutations = {
  set: (state, payload) => {
    state.items = payload;
  },
  unshift: (state, payload) => {
    state.items.unshift(payload);
  },
  swap: (state, payload) => {
    state.items.forEach((element, index) => {
      if (element.id === payload.id) {
        state.items.splice(index, 1, payload);
      }
    });
  },
};

// Base Actions for modules
export const defaultActions = {
  init: (context, payload) => {
    context.commit("set", payload);
  },
  add: (context, payload) => {
    context.commit("unshift", payload);
  },
  edit: (context, payload) => {
    context.commit("swap", payload);
  },
};

//user to extra field
export const userToExtraFields = (item) =>
  Object.keys(item).reduce(
    (obj, field) =>
      field === "extra_fields"
        ? Object.assign(
            {},
            obj,
            item["extra_fields"].reduce(
              (extra, customFields) =>
                Object.assign({}, extra, {
                  [customFields.key]:
                    customFields.value || customFields.value?.value,
                }),
              {}
            )
          )
        : Object.assign({}, { [field]: item[field] }, obj),
    {}
  );

// Remove accents + lowercase
export const normalizeStr = (str) =>
  str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const errorFormater = (error) =>
  error.response.status === 500
    ? "Erro interno no servidor"
    : [422, 404, 401, 400].includes(error.response.status)
    ? Array.isArray(error.response.data)
      ? error.response.data.reduce((s, m) => (s += JSON.stringify(m) + " "), "")
      : error.response.data?.BringerResponse?.message || "Erro desconhecido"
    : "Erro desconhecido";

// Converter packet que api retorna para o fluxo de api
export const packetToSave = (state, p) => ({
  receiver: userToExtraFields(
    // state.receivers.receivers.find((u) => u.id === p.receiver_id)
    p.receiver
  ),
  sender: userToExtraFields(
    state.senders.senders.find((u) => u.id === p.sender_id)
  ),
  package: {
    id: p.id,
    send_id: p.send_id,
    external_customer_id: p.external_customer_id,
    parcel_number: p.packet_number,
    service: p.service,
    tax_modality: p.tax_modality,
    "type of_package": p.type_package,
  },
  items: p.items.reduce((obj, i) => ({ ...obj, [i.item_id]: ({ qty: i.quantity, value: i.value }) }), {}),
  resume: {
    box: {
      height: p.box_height,
      length: p.box_lenght,
      weight: p.box_weight,
      width: p.box_width,
    },
    frete: p.freight_value,
    insuranceValue: p.insurance_value,
    unit_dimen: p.unit_dimen,
    unit_weight: p.unit_weight,
  },
});

export const cardValidator = (number) => {
  for (const [b, p] of Object.entries({
    mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
    amex: /^3[47][0-9]{13}$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
    // maestro: /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/,
    jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
    'diners-club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
  })) {
    if (number.replaceAll(' ', '').match(p)) return b
  }
  return null
}