export default {
  namespaced: true,
  state: {
    title: "Destinatários",
    itemsKey: "receivers",
    externalModule: "receivers",
    linkTo: "sendbox.package",
    btnLabel: "Próximo",
    hasEdit: true,
    createPacket: true,
    radios: true,
    groupName: "receiver",
    // API Get index
    get: {
      //urn: "user/index?group_id=c316a0c3-7d83-4fab-9638-6dd9704a7cb9",
      urn: 'user/index?group_key=["Receiver"]',
      response: "users",
    },
    fields: [
      { key: "name", label: "Nome" },
      { key: "email", label: "Email" },
      { key: "cpf", label: "CPF" },
      { key: "telephone", label: "Telefone" },
    ],
    hasExtraField: true,
  },
};
