export default {
  namespaced: true,
  state: {
    // Info
    itens: [],
    itemsKey: "itens",
    title: "Itens",
    name: "Itens",
    loading: true, //when request is call turn this to true
    // API Get index
    get: {
      urn: "item/index",
      response: "itens",
    },
    // API routes
    routes: [],
    hasEditItem: true,
    // Table fields
    fields: [
      { key: "ncm_code", label: "Código NCM" },
      { key: "harmonized_Code", label: "Código Harmonizado" },
      { key: "description", label: "Descrição" },
      { key: "sku_code", label: "Código Sku" },
      //{ key: "unit", label: "Unidade" },
      // { key: "value", label: "Valor" },
      {
        key: "lithium_battery",
        label: "Bateria de Lítio",
        formatter: (item) => (item ? "Sim" : "Não"),
      },
      {
        key: "perfumes",
        label: "Perfumes",
        formatter: (item) => (item ? "Sim" : "Não"),
      },
      {
        key: "cbd",
        label: "CBD",
        formatter: (item) => (item ? "Sim" : "Não"),
      },
    ],
    //dynamic: true,
    // Form Fields
    forms: {},
  },
  mutations: {
    set: (state, payload) => {
      state.itens = payload;
      state.loading = false;
    },
    unshift: (state, payload) => {
      state.itens.unshift(payload);
    },
    swap: (state, payload) => {
      state.itens.forEach((element, index) => {
        if (element.id === payload.id) {
          state.itens.splice(index, 1, payload);
        }
      });
    },
    remove: (state, payload) => {
      state.itens = state.itens.filter((a) => a.id !== payload);
    },
  },
  actions: {
    init: (context, payload) => {
      context.commit("set", payload);
    },
    add: (context, payload) => {
      context.commit("unshift", payload);
    },
    edit: (context, payload) => {
      context.commit("swap", payload);
    },
    remove: (context, payload) => {
      context.commit("remove", payload);
    },
  },
};
