export default {
  namespaced: true,
  state: {
    title: "Pacotes pagos",
    externalModule: "packets",
    itemsKey: "packets",
    hasLink: true,
    checkboxs: true,
    btnLabel: "Enviar Pacotes",
    btnAction: "packet/shipment_label",
    btnActionSuccess: "Pacote(s) enviado(s) com sucesso",
    parameters: {
      is_paid_out: 1
    }
  },
};
