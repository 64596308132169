<template>
  <div>
    <div class="menu-top">
      <div class="icon">
        <img src="@/assets/images/logoMenu.png" />
      </div>
      <div class="after-icon">
        <b-input-group prepend-html='<i class="icon-custom-search-bar"></i>'>
          <b-form-input
            type="search"
            placeholder="Buscar Funcionalidade"
            class="input-search"
          ></b-form-input>
        </b-input-group>
        <div class="profile">
          <b-list-group>
            <b-list-group-item
              class="d-flex align-items-center"
            >
              <div class="d-flex align-items-center mr-4">
                <div class="font-weight-bold mr-1" v-b-tooltip.hover :title="$store.getters.isAdmin ? '' : 'Créditos disponíveis'">{{ $store.getters.isAdmin ? 'Admin' : '$' + (this.$store.state.user.credito_disponivel * 1).toFixed(2) }}</div>
                <i v-if="!$store.getters.isAdmin" @click="openModalCredito" class="material-icons btn-icon-2" v-b-tooltip.hover title="Ver Extrato">attach_money</i>
                <i v-if="!$store.getters.isAdmin" @click="modalLogoRedirector" class="material-icons btn-icon-3" v-b-tooltip.hover title="Logo de invoice">image</i>
              </div>
              <div @click="openModal">
                <b-avatar rounded="lg" class="mr-3"></b-avatar>
                <div class="profile-info">
                  <span>{{ this.$store.state.user.name }}</span>
                  <!--user.email para mostrar o email, ao invés de repetir o user-->
                  <span>{{ this.$store.state.user.email }}</span>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>

    <!--
    <div class="menu-top">
      <div class="after-icon submenu-title">
        <span class="submenu-title-span">Welcome to the Xprex panel</span>
      </div>
    </div>
    -->

    <div class="menu-aside">
      <div class="bottom-icon">
        <router-link
          :to="{ name: 'Home' }"
          tag="div"
          active-class="current-page"
          exact
        >
          <!-- Custom icons -->
          <i class="menu-icon icon-custom-home"></i>

          <span>Home</span>
        </router-link>

        <router-link
          v-for="tab in tabs"
          :key="tab.key"
          :to="tab.sendTo ? { name: tab.sendTo } : { name: tab.title }"
          tag="div"
          active-class="current-page"
          :exact="tab.root"
        >
          <!-- Custom icons -->
          <i
            v-if="tab.icon.custom"
            :class="'menu-icon icon-custom-' + tab.icon.key"
          ></i>

          <!-- Material Design -->
          <i v-else class="menu-icon material-icons-outlined">{{
            tab.icon.key
          }}</i>

          <span>{{ tab.title }}</span>
        </router-link>
      </div>
      <div class="slot-page">
        <slot></slot>
      </div>
    </div>
    <b-modal id="profile" v-model="modal" size="md" centered>
      <template #modal-header></template>
      <div
        class="
          d-flex
          flex-column
          justify-content-center
          align-items-center
          mb-2
        "
      >
        <span class="m-name">{{ $store.state.user.name }}</span>
        <span class="m-email">{{ $store.state.user.email }}</span>
      </div>
      <div class="d-flex flex-column justify-content-center options">
        <span @click="openModalUser"
          ><i class="material-icons-outlined">edit</i>Editar Perfil</span
        >
        <hr />
        <span @click="changePassword"
          ><i class="material-icons-outlined">vpn_key</i>Alterar a senha</span
        >
        <hr />
        <span
          ><i class="material-icons-outlined">settings</i>Configurações</span
        >
        <hr />
        <span @click="logout"
          ><i class="material-icons-outlined">logout</i>Sair</span
        >
      </div>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>

    <b-modal id="edit-user" v-model="modalUser" size="md" centered>
      <template #modal-header></template>
      <EditUser></EditUser>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>

    <b-modal id="credito" v-model="modalCredito" size="md" centered>
      <template #modal-header>
        <b>{{ $store.state.user.name }}</b>
      </template>
      <Credito :user="$store.state.user" :notAdd="1"></Credito>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="logo" v-model="modalLogo" size="md" centered>
      <template #modal-header>
        <b>{{ $store.state.user.name }}</b>
      </template>
      <div class="d-flex flex-column">
        <div class="h-separator">
          <label for="image-logo" class="title">Imagem atual:</label>
          <span></span>
        </div>
        <div class="mt-2 mb-3">
          <a v-if="modalLogoImg" target="_blank" :href="modalLogoImg">Ver imagem</a>
          <span v-else-if="modalLogoImg === null">Sem imagem</span>
          <span v-else>Carregando ...</span>
        </div>
      
        <div class="h-separator">
          <label for="image-logo-menu" class="title">Subir nova imagem</label>
          <span></span>
        </div>
        <b-form-file
          id="image-logo-menu"
          class="image-logo-menu"
          v-model="imageLogo"
          :state="Boolean(imageLogo)"
          :browse-text="'Selecionar'"
          placeholder="Clique aqui selecionar uma imagem"
          drop-placeholder="Solte uma imagem aqui"
          accept="image/*"
        ></b-form-file>
        <div class="d-flex flex-row justify-content-end mt-3">
          <b-button @click="uploadNewImageLogo" :disabled="!imageLogo" style="width: fit-content" variant="primary">Salvar</b-button>
        </div>
      </div>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services";
import EditUser from "@/components/Modals/EditUser";
import Credito from "@/components/Modals/Credito.vue";
import { toast, errorFormater } from "@/utils";

export default {
  components: { EditUser, Credito },
  data() {
    return {
      show: false,
      modal: false,
      modalUser: false,
      modalCredito: false,
      modalLogo: false,
      modalLogoImg: undefined,
      imageLogo: null
    };
  },
  watch: {},
  computed: {
    tabs() {
      return this.$store.state.dashboard.filter(
        (a) =>
          !a.noMenu &&
          (!a.only ||
            this.$store.state.user.groups.reduce(
              (v, g) => v || a.only.includes(g.key),
              false
            ))
      );
    },
  },
  methods: {
    openModalUser() {
      this.modal = false;
      this.modalUser = true;
    },
    openModal() {
      this.modal = true;
    },
    openModalCredito() {
      this.modalCredito = true
    },
    changePassword() {
      this.$router.replace({ path: "/new-password" });
    },
    logout: function () {
      // this.$store.dispatch("logout");
      // this.$router.replace({ name: "Login" });
      api.get("auth/logout", {}).then(
        (response) => {
          //if (response.status === 200) {
          this.$store.dispatch("logout", response.data);
          // this.$router.replace({ name: "Login" });
          this.$router.go();
          //}
        },
        (error) => {
          this.$store.dispatch("logout", undefined);
          // this.$router.replace({ name: "Login" });
          this.$router.go();
          return Promise.reject(error);
        }
      );
    },
    modalLogoRedirector () {
      this.modalLogo = true
      this.modalLogoImg = undefined
      this.imageLogo = null
      api.get('user/redirector-logo/' + this.$store.state.user.id).then(res => {
        this.modalLogoImg = res?.data?.[0]?.url || null
      })
    },
    uploadNewImageLogo () {
      if(!this.imageLogo) return
      const formData = new FormData()
      formData.append('user_id', this.$store.state.user.id)
      formData.append('image', this.imageLogo)
      api({method: 'POST', url: 'user/redirector-logo', data: formData, headers: { 'Content-Type': 'multipart/form-data' }})
        .then(
          () => {
            toast(this, "success", "Imagem Salva", "Nova imagem salva com sucesso");
            this.modalLogoImg = undefined
            api.get('user/redirector-logo/' + this.$store.state.user.id).then(res => {
              this.modalLogoImg = res?.data?.[0]?.url || null
            })
          },
          e => {
            toast(this, "danger", "Erro", errorFormater(e), JSON.stringify(e.response.data));
            return Promise.reject(e);
          }
        )
    }
  },
};
</script>

<style lang="stylus" scoped>

@import '../../style/main/colors.styl';
@import '../../style/main/dimens.styl';
@import '../../style/main/fonts.styl';
@import '../../style/main/mixins.styl';

.menu-top
.menu-aside
    display flex

.menu-aside
    margin-top 4.5rem

.icon
    background-color color-theme()
    padding 8px
    flex-shrink 0
    cursor pointer
    transition 0.4s
    width menu-width-hover

    img
        min-width 100%
        // width 48px
        // height 48px

    &:hover
        background-color content-theme()

.input-group
    max-width: 40%;
    margin: 0 1rem;

    .input-group-text
        background-color white
        font-size 14px

    .input-search
        font-size 14px

.after-icon
.bottom-icon
    background-color color-theme()

.after-icon
    width 100%

.submenu-title
    margin-left menu-width
    padding 1rem 0

.slot-page
    width "calc(98% - %s)" % menu-width
    background light
    border-radius 1rem
    //margin 0 2rem 2rem 0

.bottom-icon
    width menu-width
    min-height calc(100vh - 171px) // This is not dynamic yet, i not figure how get height in px of .icon
    flex-shrink 0
    text-align left
    transition .3s

    &:hover
        width menu-width-hover

        span
            display inline

    > div
        padding 12px 0
        cursor pointer
        transition 0.3s

        &:hover
            background-color hoverBlue

    .current-page
        .menu-icon, span
            color lightBlue

        &:hover
            background-color hoverBlue

    .menu-icon, span
        display inline
        text-align center
        color grey
        width 100%
        text-align center
        no-select()
        vertical-align middle

    .menu-icon
        font-size 28px
        height 28px
        padding 0 16px 0 24px

    span
        display none
        font-size 14px
        font-family Manrope-Bold

.after-icon
    display: flex
    align-items: center

    .profile
        color white
        margin 0 6rem 0 auto
        order 2
        display flex

    .submenu-title-span
        color lightBlue
        font-size 24px
        font-weight bold

    .list-group-item
        background none
        border none
        color grey
        cursor pointer

        .profile-info
            display inline-grid
            line-height 1.35

            span:nth-child(1)
                font-weight bold

            span:nth-child(2)
                font-size 10px
                text-transform uppercase
    button
        margin 1rem

.options

  no-select()

  span
    margin 0 auto
    padding 16px 10px
    width 75%
    cursor pointer
    // border-radius 8px
    display flex
    align-items center

    i
      margin-right 8px

    &:hover
      background-color #EFEFEF

  hr
    width 75%
    margin 0 auto
    padding 0

.m-name
  font-size 1.3em
  font-weight bold

.m-email
  font-size 0.9em
</style>
<style>

.image-logo-menu .custom-file-label {
	line-height: 0.8;
}

</style>