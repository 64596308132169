<template>
  <div class="main">
    <div class="row bolds">
      <span class="col-3">Quantidade</span>
      <span class="col-3">Item</span>
      <span class="col-3">Valor</span>
      <span class="col-3">Total</span>
    </div>
    <div
      class="row"
      v-for="item in Object.keys(items).map(getItem)"
      :key="item.id"
    >
      <span class="col-3">{{ items[item.id].qty }}</span>
      <span v-b-tooltip :title="item.description" class="col-3 description">{{ item.description }}</span>
      <span class="col-3">{{ (items[item.id].value * 1).toFixed(2) }}</span>
      <span class="col-3">{{ (items[item.id].qty * +items[item.id].value).toFixed(2) }}</span>
    </div>
    <div class="row bolds">
      <span class="col-3 offset-9">{{ total }}</span>
    </div>
    <b-button @click="go" variant="primary">Próximo</b-button>
  </div>
</template>
<script>
export default {
  computed: {
    items() {
      return this.$store.state.packageSave.items;
    },
    total() {
      return Object.keys(this.items)
        .map(this.getItem)
        .reduce((t, i) => t + this.items[i.id].qty * +this.items[i.id].value, 0)
        .toFixed(2);
    },
  },
  methods: {
    getItem(id) {
      return this.$store.state.itens.itens.find((i) => i.id == id);
    },
    go() {
      this.$bvModal.hide("items");
      this.$router.replace({ path: "/sendbox/resume" });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../style/main/*'

span
    text-align center
    nowrap()

.bolds
    span
        font-weight: bold
.description
  overflow: hidden;
  text-overflow: ellipsis;
</style>
