import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// Dashboard
import Dashboard from "../views/Dashboard";

import Table from "../views/Dashboard/Table";
import Cards from "../views/Dashboard/Cards";

import Package from "../views/Dashboard/Package";

// Login
import Login from "../views/Login.vue";

// Recover Password
import RecoverPassword from "../views/RecoverPassword.vue";

// Recover Confirm
import RecoverConfirm from "../views/RecoverConfirm.vue";

// New Password
import NewPassword from "../views/NewPassword.vue";

// Password Reseted
import PasswordReseted from "../views/PasswordReseted.vue";

// Home
import Home from "../views/Dashboard/Home.vue";

import Resume from "../views/Dashboard/Resume.vue";

//Redirector
import Redirector from "../views/Dashboard/Redirector.vue";

// Payment
import Payment from "../views/Dashboard/Payment.vue";

Vue.use(VueRouter);

const dashboardTabs = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

// Generate dynamic routes for dashboard
store.state.dashboard.forEach((tab) => {
  const path = tab.root ? "/" : tab.key;

  /*
  if (
    tab.only &&
    !store.state.user.groups.reduce(
      (v, g) => v || tab.only.includes(g.key),
      false
    )
  ) {
    return;
  }
  */

  if (tab.noRoute) return;

  dashboardTabs.push(
    ...[
      {
        // Index
        path: path,
        name: tab.title,
        component: tab.layout === "cards" ? Cards : Table,
        props: { module: tab.key },
        beforeEnter: (to, from, next) => {
          if (
            tab.only &&
            !store.state.user.groups.reduce(
              (v, g) => v || tab.only.includes(g.key),
              false
            )
          ) {
            next({ name: "Home" });
            return;
          }
          next();
        },
      },
    ]
  );
});

const routes = [
  // Dashboard
  {
    path: "/",
    component: Dashboard,
    children: [
      ...dashboardTabs,

      //////////////////////////////////
      {
        path: "/redirectors/redirector",
        name: "redirectors.redirector",
        component: Redirector,
        props: () => ({
          module: "home.redirector",
          routeKey: "base",
        }),
        beforeEnter: (to, from, next) => {
          if (
            store.state.user.groups.reduce(
              (v, g) =>
                v || ["Super Admin", "RedirectorPremium"].includes(g.key),
              false
            )
          ) {
            next();
            return;
          }
          next({ name: "Home" });
        },
      },
      {
        path: "/sendbox/sender",
        name: "sendbox.sender",
        component: Table,
        props: { module: "home.sender" },
      },
      {
        path: "/sendbox/receiver",
        name: "sendbox.receiver",
        component: Table,
        props: { module: "home.receiver" },
      },
      {
        path: "/sendbox/package",
        name: "sendbox.package",
        component: Package,
        props: () => ({
          module: "home.package",
          routeKey: "base",
        }),
      },
      {
        path: "/sendbox/items",
        name: "sendbox.items",
        component: Table,
        props: { module: "home.items" },
      },
      {
        path: "/sendbox/resume",
        name: "sendbox.resume",
        component: Resume,
      },
      {
        path: "payment",
        name: "Payment",
        component: Payment,
        props: true
      },
      //////////////////////////////////
    ],
  },

  // Login
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ path: "/" });
        return;
      }
      next();
    },
  },
  // Recover Password
  {
    path: "/recover-password",
    name: "RecoverPassword",
    component: RecoverPassword,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ path: "/" });
        return;
      }
      next();
    },
  },
  // Recover Confirm
  {
    path: "/recover-confirm",
    name: "RecoverConfirm",
    component: RecoverConfirm,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ path: "/" });
        return;
      }
      next();
    },
    props: (route) => ({ cpf: route.params.cpf }),
  },

  // Reset Password
  {
    path: "/new-password",
    name: "NewPassword",
    component: NewPassword,
  },

  // Password Reseted
  {
    path: "/password-reseted",
    name: "PasswordReseted",
    component: PasswordReseted,
  },

  { path: "*", redirect: "/" }, // Not Found
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to.path)
  if (
    !["Login", "RecoverPassword", "RecoverConfirm"].includes(to.name) &&
    !store.getters.isLogged
  ) {
    next({ name: "Login" });
    return;
  }
  next();
});

export default router;
