<template>
  <div class="interface">
    <b-card class="login-card">
      <div class="logo">
        <img src="@/assets/images/logo.png" />
      </div>
      <div>
        <b-form @submit.prevent="login">
          <div class="h-separator">
            <label for="cpf" class="title">CPF/CNPJ *</label>
            <span></span>
          </div>
          <b-form-input
            id="cpf"
            v-model="user.cpf"
            type="text"
            placeholder="CPF/CNPJ"
            v-maska="['###.###.###-##', '##.###.###/####-##']"
            required
          ></b-form-input>
          <div class="h-separator">
            <label for="password" class="title">Senha *</label>
            <span></span>
          </div>
          <b-form-input
            id="password"
            v-model="user.password"
            type="password"
            placeholder="Senha"
            required
          ></b-form-input>
          <b-button type="submit">Entrar</b-button>
          <span @click="forgetP" class="forget-p">Esqueceu sua senha?</span>
        </b-form>
      </div>
    </b-card>
  </div>
</template>

<style lang="stylus" scoped>
@import '../style/main/colors.styl';
@import '../style/main/fonts.styl';

.interface
  min-height 100%
  min-height 100vh
  display flex
  align-items center
  background 50%/cover no-repeat url('../assets/images/banner.png')

.login-card
  position absolute
  right 5%
  width 350px
  // min-height 52% // old support
  // min-height 52vh
  border-radius 0.5rem
  margin 0 auto
  display flex
  background light

@media (max-width: 556px)
    .login-card
        position unset
        margin 0 auto
        width: 90%
        // min-height 75vh

.card-body, .card-body > div
  // display grid
  // justify-content space-around

.logo
  height fit-content
  margin-bottom 30px
  text-align center

.h-separator
    display flex
    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary
    span
        align-self center
    span:last-child
        border 1px dashed primary
        height 1px
    span:last-child
        flex-grow 1

form
  width 100%

  *
    font-family Manrope

  .title
    text-align center
    color primary

  label
    color light
    padding 0 0.5rem
    margin 8px 0
    border-radius 1rem
    font-weight bold
    background primary

  input, button
    height calc(1.5em + 0.75rem + 2px)
    padding 0.4rem 1rem

  input
    margin-bottom 18px
    border-radius 0.5rem
    background primary
    color secondary

  button
    width 100%
    margin 10px 0 0 0
    background blue

  .forget-p
    color blue
    cursor pointer
    text-align center
    font-size 1.1em
    display inline-block
    width 100%
    margin-top 15px

    &:hover
      text-decoration underline
</style>

<script>
import api from "@/services";
import { toast } from "@/utils";

export default {
  data: () => ({
    user: {
      cpf: "",
      password: "",
    },
    //show: false, // password show/hide eye
  }),
  methods: {
    forgetP() {
      this.$router.push({ path: "recover-password/" });
    },
    login: function () {
      api.post("login", this.user).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch("login", response.data);
            this.$router.replace({ path: "/" });
          }
        },
        (error) => {
          toast(this, "danger", "Login inválido", error.message);
          return Promise.reject(error);
        }
      );
    },
  },
};
</script>
