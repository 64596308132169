export default {
  namespaced: true,
  state: {
    // Info
    receivers: [],
    itemsKey: "receivers",
    title: "Destinatários",
    name: "Destinatário",
    loading: true, //when request is call turn this to true
    hasEdit: true,
    groupName: "receiver",
    // API Get index
    // get: {
      //urn: "user/index?group_id=c316a0c3-7d83-4fab-9638-6dd9704a7cb9",
    //  urn: 'user/index?group_key=["Receiver"]',
    //  response: "users",
    //},
    get: {
      urn: "user/receivers",
      response: "users",
    },
    async_paginate: true,
    // API routes
    routes: [],
    // Table fields
    fields: [
      { key: "name", label: "Nome" },
      { key: "email", label: "Email" },
      { key: "cpf", label: "CPF" },
      { key: "telephone", label: "Telefone" },
    ],
    hasExtraField: true,
    forms: {},
  },
  mutations: {
    set: (state, payload) => {
      state.receivers = payload;
      state.loading = false;
    },
    unshift: (state, payload) => {
      state.receivers.unshift(payload);
    },
    swap: (state, payload) => {
      state.receivers.forEach((element, index) => {
        if (element.id === payload.id) {
          state.receivers.splice(index, 1, payload);
        }
      });
    },
    remove: (state, payload) => {
      state.receivers = state.receivers.filter((a) => a.id !== payload);
    },
    reset: state => {
      state.receivers = []
      state.loading = true
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit("set", payload);
    },
    add: (context, payload) => {
      context.commit("unshift", payload);
    },
    edit: (context, payload) => {
      context.commit("swap", payload);
    },
    remove: (context, payload) => {
      context.commit("remove", payload);
    },
    reset: context => {
      context.commit("reset");
    }
  },
};
