<template>
    <div class="d-flex flex-column">
        <div>
            <b-button variant="success" @click="add">Adicionar NCM</b-button>
            <b-button variant="primary" @click="saveInfo">Salvar Alterações</b-button>
        </div>
        <b-table
        sticky-header="100vh"
        style="padding-bottom: 16px"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        :busy="isLoading"
        >
        <template v-slot:cell()="{ value, item, field: { key } }">
            <template v-if="edit != item.id">{{ value }}</template>
            <template v-else>
                <b-form-input v-model="item[key]" :list="'datalist-' + key" />
                <datalist :id="'datalist-' + key">
                    <option v-for="v in items.map(i => i[key]).filter((v, i, self) => self.indexOf(v) === i && v != item[key])" :key="'option-' + key + '-' + v">{{ v }}</option>
                </datalist>
            </template>
        </template>
        <template v-slot:cell(actions)="{ item: { id }, item }">
            <div class="btn-icons btn-actions">
            <i
                v-if="edit !== id"
                @click="onEdit(id)"
                class="material-icons btn-icon"
                >edit</i
            >
            <i
                v-else
                @click="onSave(item, id)"
                class="material-icons btn-icon"
                >check</i
            >
            <i
                @click="onDelete(id)"
                class="material-icons btn-icon red"
                style="background-color: red"
                >delete</i
            >
            </div></template
        >
        <template #empty>
            <div class="text-center text-primary my-2">
                <strong>Nenhum Registro</strong>
            </div>
        </template>
        <template #table-busy>
            <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            </div>
        </template>
        </b-table>
        <b-pagination
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        size="md"
        align="right"
        class="my-0"
        ></b-pagination>
    </div>
</template>

<script>

import api from "@/services";
import axios from "axios";
import { toast, errorFormater } from "../../utils.js";

export default {
    data: () => ({
        perPage: 8,
        currentPage: 1,
        items: [],
        fields: [
            { key: 'ncm', label: 'Código NCM', sortable: true },
            { key: 'master_cat', label: 'Super Categoria', sortable: true },
            { key: 'mid_cat', label: 'Categoria Nível 2', sortable: true },
            { key: 'cat', label: 'Categoria Nível 3', sortable: true },
            { key: 'actions', label: '' },
        ],
        edit: null,
        isLoading: false
    }),
    computed: {
        total() {
            return this.items.length
        },
        newId() {
            return this.items.reduce((max, i) => i.id > max ? i.id : max, -1) + 1
        }
    },
    methods: {
        onEdit(id) {
            this.edit = this.edit !== id ? id : null;
        },
        onSave(item, id) {
            this.items = this.items.map(o => o.id === id ? item : o)
            this.edit = null
        },
        add() {
            const id = this.newId + 0
            this.items = [...this.items, {id: id, ncm: '', cat: '', master_cat: ''}]
            this.edit = id
            setTimeout(() => this.currentPage = Math.ceil(this.total / this.perPage), 5)
        },
        onDelete(id) {
            if(confirm("Tem certeza que deseja deletar esse dado")) this.items = this.items.filter(o => o.id !== id)
        },
        saveInfo() {
            this.isLoading = true;
            
            api.post('save/ncm', { ncm: this.items}).then(
                (response) => {
              
                    if (response.status >= 200 && response.status < 300) {
                        toast(this, 'success', 'Sucesso', 'Tabela NCM salva com sucesso')    
                    }
                    this.isLoading = false;
                },
                (error) => {
                    toast(this, "danger", "Erro", errorFormater(error), JSON.stringify(error.response.data));
                    this.isLoading = false;
                    return Promise.reject(error);
                }
            );
        }
    },
    created() {
        //get items from api
        this.isLoading = true
        axios.get("https://xprex-bucket.s3.amazonaws.com/ncm.json", { headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }}).then(
            response => {
                if (response.status >= 200 && response.status < 300) {
                    this.items = response.data
                    this.isLoading = false
                }
            },
            error => {
                toast(this, "danger", "Erro", errorFormater(error), JSON.stringify(error.response.data));
                this.isLoading = false;
                return Promise.reject(error);
            }
        )
        //this.items = Array(0).fill({}).map((o,i) => ({
        //    id: i,
        //    ncm: '123456',
        //    cat: 'teste',
        //    master_cat: 'teste'
        //}))
    }
}

</script>