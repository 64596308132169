//import config from "@/config";

export default {
  namespaced: true,
  state: {
    // Info
    senders: [],

    loading: true, //when request is call turn this to true
    itemsKey: "senders",
    title: "Remetentes",
    name: "Remetente",
    hasEdit: true,
    groupName: "sender",
    // API Get index
    get: {
      //urn: "user/index?group_ids=['4711cc38-9622-4000-8b7c-cb0f089a59d9']",
      urn: 'user/index?group_key=["Sender"]',
      response: "users",
    },

    // API routes
    routes: [
      {
        key: "add",
        form: "base",
        title: "Add New Sender",
        success: "New Sender has been registered",
        urn: "sender/create",
        response: "sender",
        method: "post",
        dispatch: true,
      },
    ],
    hasExtraField: true,
    fields: [
      { key: "name", label: "Nome" },
      { key: "email", label: "Email" },
      { key: "telephone", label: "Telefone" },
    ],
    form: {},
  },
  mutations: {
    set: (state, payload) => {
      state.senders = payload;
      state.loading = false;
    },
    unshift: (state, payload) => {
      state.senders.unshift(payload);
    },
    swap: (state, payload) => {
      state.senders.forEach((element, index) => {
        if (element.id === payload.id) {
          state.senders.splice(index, 1, payload);
        }
      });
    },
    remove: (state, payload) => {
      state.senders = state.senders.filter((a) => a.id !== payload);
    },
  },
  actions: {
    init: (context, payload) => {
      context.commit("set", payload);
    },
    add: (context, payload) => {
      context.commit("unshift", payload);
    },
    edit: (context, payload) => {
      context.commit("swap", payload);
    },
    remove: (context, payload) => {
      context.commit("remove", payload);
    },
  },
};
