export default {
  namespaced: true,
  state: {
    title: "Serviços",
    itemsKey: "redirectorservice",
    hasEdit: true,

    fields: [
      { key: "type", label: "" },
      { key: "min", label: "" },
      { key: "max", label: "" },
      { key: "value", label: "" },
      { key: "actions", label: "" },
    ],
    field: [
      { value: "to", text: "to" },
      { value: "adicional", text: "adicional" },
      { value: "maior que", text: "maior que" },
      { value: "menor que", text: "menor que" },
    ],
  },
};
