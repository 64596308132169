<template>
    <Interface style="margin-top: -10px;" title="Pagamento" noHeader btnAsEvent btnLabel="Continuar">
        <b-row class="row mx-0 mt-4 mb-2">
            <b-col cols="6">
                <div class="d-flex flex-column">

                    <!-- Formulário Dados pessoais usuário -->
                    <div class="d-flex flex-column">
                        <div class="my-2 h-separator">
                            <label class="subtitle">Dados pessoais</label>
                            <span></span>
                        </div>
                        <div class="my-2">
                            <b-form ref="formUser" @submit.stop.prevent=";">
                                <b-row>
                                    <b-col cols="6">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-name" class="subtitle">Nome completo *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-name"
                                                v-model="formUser['name']"
                                                type="text"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>
                                    <b-col cols="6">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-email" class="subtitle">Email *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-email"
                                                v-model="formUser['email']"
                                                type="text"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>
                                    <b-col cols="4">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-document" class="subtitle">Documento *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-document"
                                                v-model="formUser['document']"
                                                type="text"
                                                v-maska="['###.###.###-##', '##.###.###/####-##']"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="5">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-birthdate" class="subtitle">Data de Nascimento *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-birthdate"
                                                v-model="formUser['birthdate']"
                                                type="text"
                                                v-maska="'##/##/####'"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="3">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-gender" class="subtitle">Gênero *</label>
                                                <span></span>
                                            </div>
                                            <b-form-select
                                                class="form-control-white-bg"
                                                id="input-user-gender"
                                                v-model="formUser['gender']"
                                                :options="[{ value: 'male', text: 'Masculino' }, { value: 'female', text: 'Feminino' }]"
                                                required
                                            ></b-form-select>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="6">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-telephone" class="subtitle">Telefone de casa *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-telephone"
                                                v-model="formUser['telephone']"
                                                type="text"
                                                v-maska="'(##) ########'"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="6">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-g-user-cellphone" class="subtitle">Celular *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-cellphone"
                                                v-model="formUser['cellphone']"
                                                type="text"
                                                v-maska="'(##) #########'"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="3">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-state" class="subtitle">Estado *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-state"
                                                v-model="formUser['state']"
                                                type="text"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="5">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-city" class="subtitle">Cidade *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-city"
                                                v-model="formUser['city']"
                                                type="text"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="4">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-zip" class="subtitle">CEP / ZIP Code *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-zip"
                                                v-model="formUser['zip']"
                                                type="text"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                    <b-col cols="12">

                                        <b-form-group>
                                            <div class="h-separator">
                                                <label for="input-user-line" class="subtitle">Endereço *</label>
                                                <span></span>
                                            </div>
                                            <b-form-input
                                                class="form-control-white-bg"
                                                id="input-user-zip"
                                                v-model="formUser['line_1']"
                                                type="text"
                                                required
                                            ></b-form-input>
                                        </b-form-group>

                                    </b-col>

                                </b-row>
                            </b-form>
                        </div>
                    </div>

                    <!-- Cartão de Crédito -->
                    <div class="d-flex flex-column">
                        <div class="my-2 h-separator">
                            <label class="subtitle">Cartão de Crédito</label>
                            <span></span>
                        </div>
                        <div @click="cardSelected = c.id" v-for="c in creditCards" :key="c.id" class="d-flex flex-row justify-content-between align-items-center card my-2">
                            <div class="d-flex flex-row align-items-center">
                                <div class="pl-1">
                                    <b-form-radio class="d-flex" v-model="cardSelected" :name="'card-' + c.id" :value="c.id"></b-form-radio>
                                </div>
                                <font-awesome-icon :icon="0 ? ['fab', 'cc-' + c.brand.toLowerCase()] : ['fas', 'credit-card']" class="pl-1 pr-2" />
                                <span>{{ c.first_six_digits.substring(0,4) }} **** **** {{ c.last_four_digits }}</span>
                            </div>
                            <div @click="deleteCard(c.id)" class="d-flex flex-row align-items-center">
                                <span class="material-icons mr-2">delete</span>
                            </div>
                        </div>
                        <div @click="newCardModal" class="d-flex flex-row align-items-center card my-2">
                            <i class="material-icons">add</i>
                            <span>Cadastrar novo cartão</span>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="6">
                <div class="d-flex flex-column justify-content-between h-100">
                    <div v-for="r in [0,1]" :key="'total-rep-' + r" class="total mt-4">
                        <b-row v-for="i in items" :key="i.name" class="p-1" style="margin-left: -8px; margin-right: -8px;">
                            <b-col cols="8">{{ i.name }}</b-col>
                            <b-col class="text-right" cols="4">R$ {{ (i.value * 1).toFixed(2) }}</b-col>
                        </b-row>
                        <b-row class="p-1" style="margin-left: -8px; margin-right: -8px;">
                            <b-col class="font-weight-bold" cols="8">Total</b-col>
                            <b-col class="font-weight-bold text-right" cols="4">R$ {{ total }}</b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>

        <div @click="buyWithCard" class="btnS mt-4">Continuar</div>

        <b-modal size="xl"
            id="modal-cartao"
            title="Cadastrar novo Cartão"
            @ok="newCard"
            no-close-on-backdrop
            v-model="modalCard"
            ok-title="Salvar"
            cancel-title="Cancelar"
        >
            <b-form ref="form" @submit.stop.prevent="newCard">
                <b-row>
                    <b-col cols="4">

                        <b-form-group
                            id="input-g-number"
                            class="form-group"
                            label="Número do cartão"
                            label-for="input-number"
                        >
                        <b-input-group>
                                <template #prepend>
                                    <b-input-group-text><strong class="text-info"><font-awesome-icon :icon="form.brand ? ['fab', 'cc-' + form.brand] : ['fas', 'credit-card']" /></strong></b-input-group-text>
                                </template>
                                <b-form-input
                                    class="form-control-white-bg"
                                    id="input-number"
                                    v-model="form['number']"
                                    type="text"
                                    v-maska="'#### #### #### ####'"
                                    required
                                ></b-form-input>

                            </b-input-group>
                        </b-form-group>

                    </b-col>

                    <b-col cols="4">

                        <b-form-group
                            id="input-g-name"
                            class="form-group"
                            label="Nome impresso no cartão"
                            label-for="input-name"
                        >
                            <b-form-input
                                class="form-control-white-bg"
                                id="input-name"
                                v-model="form['name']"
                                type="text"
                                required
                            ></b-form-input>

                        </b-form-group>

                    </b-col>

                    <b-col cols="4">

                        <b-form-group
                            id="input-g-date"
                            class="form-group"
                            label="Data de validade"
                            label-for="input-date"
                        >
                            <b-form-input
                                class="form-control-white-bg"
                                id="input-date"
                                v-model="form['date']"
                                type="text"
                                v-maska="'##/##'"
                                required
                            ></b-form-input>

                        </b-form-group>

                    </b-col>

                    <b-col cols="4">

                        <b-form-group
                            id="input-g-cvv"
                            class="form-group"
                            label="CVV"
                            label-for="input-cvv"
                        >
                            <b-form-input
                                class="form-control-white-bg"
                                id="input-cvv"
                                v-model="form['cvv']"
                                v-maska="'####'"
                                type="text"
                                required
                            ></b-form-input>

                        </b-form-group>

                    </b-col>

                    <b-col cols="4">

                        <b-form-group
                            id="input-g-document"
                            class="form-group"
                            label="Documento do portador do cartão"
                            label-for="input-document"
                        >
                            <b-form-input
                                class="form-control-white-bg"
                                id="input-document"
                                v-model="form['document']"
                                type="text"
                                v-maska="['###.###.###-##', '##.###.###/####-##']"
                                required
                            ></b-form-input>

                        </b-form-group>

                    </b-col>

                </b-row>
            </b-form>
        </b-modal>
    </Interface>
</template>

<script>

// import api from '@/services'
import { cardValidator, toast, userToExtraFields } from '@/utils'
import Interface from "@/components/Dashboard/Interface";

export default {
  components: { Interface },
  data: () => ({
    form: {}, // card
    formUser: {}, // dados pessoais
    cardSelected: null,
    creditCards: [],
    modalCard: false
  }),
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  created () {
    // get extrafield
    this.formUser = {
      id: this.$store.state.user.id,
      name: this.$store.state.user.name,
      email: this.$store.state.user.email,
      document: this.$store.state.user.cpf,
      gender: 'male',
      ...userToExtraFields(this.$store.state.user)
    }
    this.formUser = {
        ...this.formUser,
        line_1: this.formUser.endereco,
        zip: this.formUser.zip || this.formUser.cep,
        city: this.formUser.cidade, 
        state: this.formUser.uf,
        cellphone: this.formUser.telephone
    }
    /*
    api.get('user/getExtraFields/' + this.$store.state.user.id).then(
      res => {
        this.formUser = res.data
      },
      error => {
        toast(this, 'danger', 'Erro', error.message)
        return Promise.reject(error)
      }
    )
    api.get('user/indexCard/' + this.$store.state.user.id).then(
      res => {
        this.creditCards = res.data?.data || []
      },
      error => {
        toast(this, 'danger', 'Erro', error.message)
        return Promise.reject(error)
      }
    )
    */
  },
  watch: {
    'form.number': {
      handler (v) {
        this.form.brand = cardValidator(v)
      }
    }
  },
  computed: {
    total () {
      return this.items.reduce((t, i) => t + i.value * 1, 0).toFixed(2)
    }
  },
  methods: {
    newCardModal () {
      if (!this.$refs.formUser.checkValidity()) {
        toast(this, 'info', 'Formulário incompleto', 'É necessário preencher os dados pessoais para criação de cartão')
        return
      }
      this.form = {}
      this.modalCard = true
    },
    newCard (e) {
      e.preventDefault()

      if (!this.$refs.form.checkValidity()) {
        console.log('incompleto')
        // toast(this, 'info', 'Formulário incompleto', 'Preencha os campos corretamente')
        return
      }
      this.creditCards = [...this.creditCards, {
        brand: this.form.brand,
        first_six_digits: this.form.number.substring(0, 4),
        last_four_digits: this.form.number.substring(15,20),
        id: this.form.number
      }]
      this.modalCard = false
      /*
      api.post('user/createCard', { card: this.form, user: this.formUser }).then(
        res => {
          this.creditCards = [...this.creditCards, res.data]
          this.modalCard = false
        },
        error => {
          toast(this, 'danger', 'Erro', error.message)
          return Promise.reject(error)
        }
      )
      */
    },
    deleteCard (id) {
      this.$bvModal.msgBoxConfirm('Tem certeza que deseja remover esse cartão?', {
        title: 'Deletar cartão',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (!value) return
          this.creditCards = this.creditCards.filter(c => c.id !== id)
          /*
          api.post('user/deleteCard', { user_id: this.$store.state.user.id, card_id: id }).then(
            () => {
              this.creditCards = this.creditCards.filter(c => c.id !== id)
            },
            error => {
              toast(this, 'danger', 'Erro', error.message)
              return Promise.reject(error)
            }
          )
          */
        })
        .catch(err => Promise.reject(err))
    },
    buyWithCard () {
      if (!this.cardSelected) {
        toast(this, 'info', 'Aviso', 'Selecione um cartão para continuar')
        return
      }
      /*
      api.post('user/buyWithCard', {
        curso_id: this.curso.id,
        user_id: this.$store.state.user.id,
        card_id: this.cardSelected
      }).then(
        res => {
          console.log(res.data)
          console.log(res.data.status)
          this.$router.push({ name: 'PagamentoStatus', query: { status: res.data.status } })
        },
        error => {
          toast(this, 'danger', 'Erro', error.message)
          return Promise.reject(error)
        }
      )
      */
    }
  }
}

</script>

<style lang="stylus" scoped>

    @import '../../style/main/colors.styl'
    @import '../../style/main/fonts.styl'
    @import '../../style/main/mixins.styl'

    .card
        // background-color #282828
        padding 8px
        border 1px solid #777777
        font-size 1.3em
        cursor pointer
        background-color white
        transition .2s

        &:hover
            background-color rgba(0, 0, 0, .1)

    .btnS
        color white
        padding 6px 30px
        cursor pointer
        height 38px
        background-color blue
        border-radius 5px
        margin 0 24px 0 auto
        width fit-content
        text-align center
        transition .2s
        font-family: Calibri;

        &:hover
            background-color alpha(primary, 0.8)

    .total
        background-color white
        padding 8px
        font-size 1.3em
        border-radius 4px
        border 1px solid #777777

    .h-separator
        display: flex
        margin-bottom: 8px
        padding-bottom: 0 !important

        .subtitle
            padding: 3px 10px
            font-size: 14px
            text-transform: uppercase
            color: grey
            font-weight: bold
            border-radius: 1rem
            background: primary

        span
            align-self: center

        span.subtitle
            + span
                border: 1px dashed greyDark
                height: 1px
                width: 50px

        span:first-child
        span:last-child
            border: 1px dashed greyDark
            height: 1px

        span:first-child
            width: 20px

        span:last-child
            flex-grow: 1

</style>
