<template>
  <div
    class="main-row"
    v-if="
      $store.getters.isRedirector ||
      $store.getters.isRedirectorPremium ||
      $store.getters.isAdmin
    "
  >
    <b-row>
      <b-col cols="2">
        <div @click="reset">
          <div class="box">
            <img class="ml" src="@/assets/images/box-icon.svg" />
            <span>Enviar Pacote</span>
          </div>
        </div>
      </b-col>
      <b-col cols="2" offset="1" v-if="$store.getters.isAdmin">
        <div @click="callModalNCM">
          <div class="box">
            <img src="@/assets/images/list.svg" />
            <span>Tabela NCM</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal size="xl" centered id="modal-ncm" v-model="modalNCM">
      <template #modal-header></template>
      <NCM />
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
  </div>
</template>

<script>

import NCM from "@/components/Modals/NCM";

export default {
  name: "Home",
  components: { NCM },
  data: () => ({
    modalNCM: false
  }),
  methods: {
    reset() {
      this.$store.dispatch("packageSave", {});
      this.$router.push({ name: "sendbox.sender" });
    },
    callModalNCM() {
      this.modalNCM = true
    }
  },
};
</script>

<style lang="stylus" scoped>

@import '../../style/main/colors.styl';

.main-row
    padding 2rem 3rem

    a:hover
        text-decoration none

    .box
        display inline-grid
        background #414D55
        padding 2rem 3rem
        color #C4C4C4
        border-radius .5rem
        justify-content center
        align-items center
        cursor pointer
        transition 0.4s
        margin-bottom 1.5rem
        width 210px
        height 193px

        .ml
            margin-left 13px

        &:hover
            background grey

        span
            margin-top 15px
            text-align center
            font-weight bold
</style>
