export default {
  namespaced: true,
  state: {
    title: "Itens",
    itemsKey: "itens",
    externalModule: "itens",
    linkTo: "sendbox.resume",
    btnLabel: "Próximo",
    //loading: true,
    //dynamic: true,
    unselectable: true,
    noHover: true,
    hasQty: true,
    createPacket: true,
    fields: [
      { key: "ncm_code", label: "Código NCM" },
      { key: "harmonized_Code", label: "Código Harmonizado" },
      { key: "description", label: "Descrição" },
      { key: "sku_code", label: "Código Sku" },
      //{ key: "unit", label: "Unidade" },
      //{ key: "value", label: "Valor" },
      {
        key: "lithium_battery",
        label: "Bateria de Lítio",
        formatter: (item) => (item ? "Sim" : "Não"),
      },
      {
        key: "perfumes",
        label: "Perfumes",
        formatter: (item) => (item ? "Sim" : "Não"),
      },
      {
        key: "cbd",
        label: "CBD",
        formatter: (item) => (item ? "Sim" : "Não"),
      },
    ],
  },
};
