<template>
  <Interface
    :title="state.title"
    :paths="createPacketPaths"
    btnLabel="Próximo"
    :btnLinkTo="{ name: linkTo }"
    noHeader
    separator
    noModal
    :noBtn="currentRouteName === 'sendbox.package'"
  >
    <b-form @submit="onSubmit" ref="form">
      <b-row>
        <b-col
          v-for="field in editableFields"
          :key="field.key"
          :cols="field.col"
        >
          <b-form-group :id="'input-add-group-' + field.key">
            <div class="h-separator">
              <label :for="'input-add-' + field.key" class="title"
                >{{ field.label }} *</label
              >
              <span></span>
            </div>
            <b-form-input
              v-if="
                ['text', 'number', 'email', 'password'].includes(field.type)
              "
              :id="'input-add-' + field.key"
              v-model="form[field.key]"
              :type="field.type"
              :min="field.min"
              :placeholder="field.placeholder ? field.placeholder : field.label"
              required
            ></b-form-input>

            <b-form-textarea
              v-if="field.type === 'textarea'"
              :id="'input-add-' + field.key"
              v-model="form[field.key]"
              placeholder="Conteúdo ..."
              rows="3"
              max-rows="12"
            ></b-form-textarea>
            <b-form-select
              v-if="field.type === 'select'"
              v-model="form[field.key]"
              :options="
                field.key === 'tax_modality'
                  ? form.service === 'BPSI-03'
                    ? field.options2
                    : field.options
                  : field.options
              "
              required
            >
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Por favor, selecione uma opção</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        v-if="currentRouteName === 'sendbox.package'"
        type="submit"
        variant="primary"
        >Salvar</b-button
      >
      <b-button
        type="submit"
        variant="danger"
        :to="{ path: '/sendbox/receiver' }"
        >Voltar</b-button
      >
      <div v-for="field in hiddenFields" :key="field.key">
        <input type="hidden" :value="hiddenValue(field)" />
      </div>
    </b-form>
  </Interface>
</template>
<style lang="stylus" scoped>
@import '../../style/main/colors.styl'

.h-separator

    display flex

    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary

    span
        align-self center

    span:last-child
        border 1px dashed primary
        height 1px

    span:last-child
        flex-grow 1
.btn
  justify-content space-between
  margin-left 10px
  float right
</style>
<script>
import Interface from "@/components/Dashboard/Interface";
// import api from "@/services";
// import { toast } from "@/utils";

export default {
  components: { Interface },
  data: () => ({
    form: {},
  }),
  watch: {
    "form.service": function () {
      if (this.form.tax_modality === "ddu") {
        this.form.tax_modality = undefined;
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    state() {
      let path = this.module.split(".");
      let state = this.$store.state;

      path.forEach((e) => {
        state = state[e];
      });

      return state;
    },
    title() {
      return this.state.title;
    },
    //action() {
    //  return this.state.routes.find((r) => r.key === this.routeKey);
    //},
    fields() {
      return this.state.forms[this.routeKey];
    },
    hiddenFields() {
      return this.fields.filter((f) => f.type === "hidden");
    },
    editableFields() {
      return this.fields.filter((f) => f.type !== "hidden");
    },
    linkTo() {
      return this.state.linkTo;
    },
    createPacketPaths() {
      return this.state.createPacket
        ? [
            { name: "Remetente", route: "sendbox.sender" },
            { name: "Destinatário", route: "sendbox.receiver" },
            { name: "Detalhes do Pacote", route: "sendbox.package" },
            { name: "Itens", route: "sendbox.items" },
            { name: "Resumo", route: "sendbox.resume" },
          ]
        : undefined;
    },
  },
  props: {
    itemId: {
      required: false,
      type: String,
    },
    routeKey: {
      // refers to api route, not vue router
      required: true,
      type: String,
    },
    module: {
      required: true,
      type: String,
    },
  },
  created() {
    this.form = this.$store.state.packageSave.package || {};
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$store.dispatch("packageSave", {
        ...this.$store.state.packageSave,
        package: this.form,
      });
      this.$router.replace({ path: "/sendbox/items" });
      /*
      api.post("packet/create", this.form).then(
        (response) => {
          // console.log(response);
          if (response.status === 200) {
            toast(
              this,
              "success",
              "Package registred",
              "Package has been registred with success"
            );
            let routeName = this.$route.name.split(".")[1];
            if (routeName) {
              this.$store.state.selected_infos[routeName] = response.data.packet;
              this.$store.dispatch(
                "selected_infos",
                Object.assign(
                  {},
                  { [routeName]: response.data.packet },
                  this.$store.state.selected_infos
                )
              );
            }
            this.$router.replace({ path: "/sendbox/items" });
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Package cannot be registred",
            error.message +
              (error.response.status === 422 ? " => " + error.response.data.errors : "")
          );
          // console.log(error);
          return Promise.reject(error);
        }
      );*/
    },
  },
  /*methods: {
    dataState(field) {
      // For build Select Fields using existing data from store
      const s = this.$store.state;

      return field.store === undefined
        ? s[this.module][s[this.module].itemsKey] // same action module
        : s[field.store][field.store]; // custom module
    },
    confirmSubmit(e) {
      e.preventDefault(); // Prevent modal from closing
      this.submit();
    },
    hiddenValue(field) {
      // For Hidden inputs

      if (field.only !== undefined && !field.only.includes(this.action.key)) {
        return;
      }

      const value = field.value;

      // If const value
      if (typeof value === "string") {
        this.form[field.key] = value;
      }

      // If value from item
      if (value.item !== undefined) {
        this.form[field.key] = this.item[value.item];
      }

      // If value from store
      if (value.store !== undefined) {
        let data = this.$store;
        value.store.split(".").forEach((element) => {
          data = data[element];
        });
        this.form[field.key] = data;
      }

      return this.form[field.key];
    },
    submit() {
      // Form Validation
      if (!this.$refs.form.checkValidity()) {
        toast(
          this,
          "primary",
          "Formulário Inválido",
          "Preencha os campos corretamente"
        );
        return;
      }

      // Configure Request Method
      const request = this.action.method === "post" ? api.post : api.put;

      // Send Request to Api
      request(this.action.urn, this.form).then(
        (response) => {
          // console.log(response)
          if ([200, 201, 204].includes(response.status)) {
            // Dispatch a action with data
            if (this.action.dispatch) {
              const data = response.data[this.action.response];
              this.$store.dispatch(this.module + "/" + this.action.key, data);
            }

            // this.form = {}

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("form-modal");
            });

            // Success Alert
            toast(this, "success", "Sucesso", this.action.success);
          }
        },
        (error) => {
          // Fail Alert
          toast(
            this,
            "danger",
            "Error " + this.title,
            error.message +
              (error.response.status === 422
                ? " => " + error.response.data.errors
                : "")
          );
          return Promise.reject(error);
        }
      );
    },
    toOptions: toOptions,
  },*/
};
</script>
