<template>
  <div class="main">
    <div class="h-separator">
      <span></span>
      <span class="title">Item</span>
      <span></span>
    </div>
    <b-form @submit="onSubmit" ref="form">
      <b-row>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-master-cat" class="title"
                >Super Categoria</label
              >
              <span></span>
            </div>

            <b-form-select
              id="input-add-master-cat"
              v-model="masterCatSelected"
              :options="masterCats"
            >
              <b-form-select-option :value="null" disabled
                >Escolha uma super categoria para o item</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-mid-cat" class="title"
                >Categoria Nível 2</label
              >
              <span></span>
            </div>

            <b-form-select
              id="input-add-mid-cat"
              v-model="midCatSelected"
              :options="midCats"
            >
              <b-form-select-option :value="null"
                >Escolha uma categoria nível 2 para o item</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-cat" class="title">Categoria Nível 3</label>
              <span></span>
            </div>

            <b-form-select
              ref="select"
              id="input-add-cat"
              v-model="catSelected"
              :options="cats"
            >
              <b-form-select-option :value="catSelected"
                >Escolha uma categoria nível 3 para o item</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-product" class="title">Código NCM *</label>
              <span></span>
            </div>
            <b-form-input
              id="input-add-product"
              v-model="form.ncm_code"
              type="number"
              placeholder="Código NCM"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label
                v-b-tooltip.html
                title="<a target='_blank' href='https://efi.correios.com.br/app/ncm/ncm.php'>Códigos Harmonizados</a>"
                for="input-add-harmonized"
                class="title"
                >Código Harmonizado *</label
              >
              <span></span>
            </div>
            <b-form-input
              id="input-add-harmonized"
              v-model="form.harmonized_Code"
              type="number"
              placeholder="Código Harmonizado"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-description" class="title"
                >Descrição do produto *</label
              >
              <span></span>
            </div>
            <b-form-input
              id="input-add-description"
              v-model="form.description"
              type="text"
              placeholder="Descrição do produto"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-sku" class="title">Código Sku *</label>
              <span></span>
            </div>
            <b-form-input
              id="input-add-sku"
              v-model="form.sku_code"
              type="text"
              placeholder="Código Sku"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
       <!--  <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-value" class="title"
                >Valor Unitário ($) *</label
              >
              <span></span>
            </div>
            <b-form-input
              id="input-add-unit"
              v-model="form.unit"
              type="number"
              step=".01"
              placeholder="Valor em Dólares"
              required
            ></b-form-input>
          </b-form-group>
        </b-col> -->
        <b-col cols="2">
          <b-form-checkbox
            v-model="form.lithium_battery"
            name="check-button"
            switch
          >
            Bateria de lítio
          </b-form-checkbox>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox v-model="form.perfumes" name="check-button" switch>
            Perfumes
          </b-form-checkbox>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox
            v-model="form.cbd"
            name="check-button"
            switch
          >
            CBD
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button type="submit" variant="primary">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
import api from "@/services";
import axios from "axios";
import { toast, errorFormater } from "@/utils";

export default {
  data: () => ({
    form: {
      ncm_code: "",
      harmonized_Code: "",
      description: "",
      sku_code: "",
      value: "",
      weight: "",
      quantity: "",
      totalValue: "",
      lithium_battery: false,
      perfumes: false,
      cbd: false
    },
    masterCatSelected: null,
    midCatSelected: null,
    catSelected: null,
    catsData: [],
    loadingCats: false,
  }),
  created() {
    this.loadingCats = true;
    axios
      .get("https://xprex-bucket.s3.amazonaws.com/ncm.json", {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            this.catsData = response.data;
            this.loadingCats = false;
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          this.loadingCats = false;
          return Promise.reject(error);
        }
      );
    this.catSelected = this.cats[0].value;
  },
  updated() {
    this.catSelected = this.cats[0].value;
  },
  computed: {
    masterCats() {
      return this.catsData
        .map((o) => o.master_cat)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((o) => ({ value: o, text: o }));
    },
    midCats() {
      return this.catsData
        .filter((o) => o.master_cat === this.masterCatSelected)
        .map((o) => o.mid_cat)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((o) => ({ value: o, text: o }));
    },
    cats() {
      return this.catsData
        .filter((o) => o.mid_cat === this.midCatSelected)
        .map((o) => ({ value: o.id, text: o.cat }));
    },
  },
  watch: {
    "form.unit": {
      handler(value) {
        this.form.totalValue = value * (this.form.quantity || 1);
        this.form.value = value;
      },
      deep: true,
    },
    "form.quantity": {
      handler(value) {
        this.form.totalValue = value * (this.form.value || 0);
      },
      deep: true,
    },
    "form.ncm_code": {
      handler(value) {
        this.form.harmonized_Code = value.slice(0, -2);
        // if(value !== this.catsData.find(o => o.id === this.catSelected)?.ncm) {
        // this.masterCatSelected = null // loop infinite between watchers
        // }
      },
      deep: true,
    },
    masterCatSelected: {
      handler(value) {
        if (value) this.form.ncm_code = "";
        this.midCatSelected = null;
      },
    },
    midCatSelected: {
      handler(value) {
        if (value) this.form.ncm_code = "";
        this.catSelected = null;
      },
    },
    catSelected: {
      handler(value) {
        if (
          (this.masterCatSelected && this.midCatSelected) ||
          this.cats[0].text === null
        ) {
          this.form.ncm_code =
            this.catsData.find((o) => o.id === value)?.ncm || "";
          this.catSelected = this.cats[0].value;
        }
      },
      deep: true,
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      api.post("item/create", this.form).then(
        (response) => {
          // console.log(response);
          if (response.status === 200) {
            this.$store.dispatch("itens/add", response.data.item);

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-3");
            });

            this.$emit("finish");

            toast(
              this,
              "success",
              "Item registrado",
              "Item foi registrado com sucesso"
            );
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Item não pode ser registrado",
            error.message +
              (error.response.status === 422
                ? " => " + error.response.data.errors
                : "")
          );
          return Promise.reject(error);
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../style/main/colors.styl';

.main {
  // background-color light
  .h-separator {
    display: flex;

    .title {
      padding: 0px 10px;
      font-size: 14px;
      color: light;
      font-weight: bold;
      border-radius: 1rem;
      background: primary;
    }

    span {
      align-self: center;
    }

    span:last-child {
      border: 1px dashed primary;
      height: 1px;
    }

    span:last-child {
      flex-grow: 1;
    }
  }

  .custom-switch {
    margin-top: 40px;
  }

  button {
    float: right;
    width: 100px;
    background-color: #4472C4;
    font-size: 14px;
  }
}
</style>
