<template>
  <div class="dash">
    <Menu>
      <transition name="page-transition" mode="out-in">
        <router-view
          @callModal="callModal"
          :key="$route.fullPath"
        ></router-view>
      </transition>
    </Menu>
    <div>
      <!-- Modals -->
      <!-- <Delete :item="item" :action="action" :module="module" :title="'del'"/> -->
    </div>
  </div>
</template>

<style lang="stylus" scoped>

@import '../../style/main/colors.styl';

.dash {
    background: darkBlue;
}

.page-transition-enter, .page-transition-leave-to
    //transform translateX(-30px)
    opacity 0

.page-transition-enter-active, .page-transition-leave-active
    transition all 0.2s
</style>

<script>
import Menu from "@/components/Dashboard/Menu";

// import Delete from '@/components/Modals/Delete'

import api from "@/services";
import { toast } from "@/utils";

export default {
  name: "Dashboard",
  components: {
    Menu, // Delete,
  },
  data: () => ({
    item: null,
    action: {},
    module: "",
  }),

  methods: {
    getData() {
      this.$store.state.dashboard
        .filter((a) => !a.noMenu && !a.async)
        .forEach((m) => {
          const info = this.$store.state[m.key].get;

          api.get(info.urn).then(
            (response) => {
              if (response.status === 200) {
                this.$store.dispatch(
                  m.key + "/init",
                  response.data[info.response]
                );
              }
            },
            (error) => {
              toast(this, "danger", `Erro ao obter ${m.title}`, error.message);
              return Promise.reject(error);
            }
          );

          if (this.$store.state[m.key].get2) {
            const info = this.$store.state[m.key].get2;

            api.get(info.urn).then(
              (response) => {
                if (response.status === 200) {
                  this.$store.dispatch(
                    m.key + "/init",
                    response.data[info.response]
                  );
                }
              },
              (error) => {
                toast(
                  this,
                  "danger",
                  `Erro ao obter ${m.title}`,
                  error.message
                );
                return Promise.reject(error);
              }
            );
          }
        });
    },
    getExtraFields() {
      this.$store.state.extrafield.keys.forEach((e) => {
        api
          .get(
            "extra_field/useringroup?group_key=" +
              (e.charAt(0).toUpperCase() + e.slice(1))
          )
          .then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                this.$store.dispatch("extrafield/init", {
                  group: e,
                  extrafields: response.data.extra_fields
                    .sort((a, b) => (a.order || 999) - (b.order || 999))
                    .map((e) => ({ ...e, col: 3 })),
                });
              }
            },
            (error) => {
              toast(
                this,
                "danger",
                `Erro ao obter extrafields do grupo ${e}`,
                error.message
              );
              return Promise.reject(error);
            }
          );
      });
    },
    updateCredito() {
      api.post('user/credito', { user_id: this.$store.state.user.id }).then(
        res => {
          this.$store.dispatch("updateCredito", { disponivel: res.data.credito_disponivel.value, utilizado: res.data.credito_utilizado.value });
        },
        (error) => {
          toast(
            this,
            "danger",
            `Erro ao obter créditos`,
            error.message
          );
          return Promise.reject(error);
        }
      )
    },
    callModal(modal, item, action, module) {
      this.item = item;
      this.action = action;
      this.module = module;
      this.$bvModal.show(modal);
    },
  },
  created() {
    this.getExtraFields();
    this.getData();
    if(!this.$store.getters.isAdmin) this.updateCredito()
  },
};
</script>
