export default {
  namespaced: true,
  state: {
    title: "Pacotes a pagar",
    externalModule: "packets",
    itemsKey: "packets",
    hasLink: true,
    checkboxs: true,
    btnLabel: "Gerar remessa",
    btnLabelAdmin: "Pagar pacotes",
    btnAction: "packet/shipment_create",
    btnActionAdmin: "packet/shipment_paidout",
    btnActionSuccess: "Remessa gerada com sucesso",
    parameters: {
      is_paid_out: 0
    }
  },
};
