import Sender from "./sender";
import Receiver from "./receiver";
import Package from "./package";
import Items from "./items";
import Resume from "./resume";
import Redirector from "./redirector";

export default {
  namespaced: true,
  state: {
    title: "Home",
  },
  modules: {
    sender: Sender,
    redirector: Redirector,
    receiver: Receiver,
    package: Package,
    items: Items,
    resume: Resume,
  },
};
