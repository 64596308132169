<template>
    <div>
        <div class="row">
            <div class="col-12 text-center" v-if="loadingExtrato">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
            <template v-else>
                <template v-for="e in extrato">
                    <span :key="'description-' + e.id" class="col-9">{{ e.description }} - {{ dateFormat(e.updated_at) }}</span>
                    <span :key="'value-' + e.id" :class="'col-3 text-right font-weight-bold text-' + (e.value > 0 ? 'success' : 'danger')">{{ numFormat(e.value) }}</span>
                    <div :key="'separator-' + e.id" style="border-top: 1px solid #DDDDDD; width: 95%; margin: 4px auto"></div>
                </template>
            </template>
            <span class="col-9 font-weight-bold">Crédito disponível</span>
            <span class="col-3 text-right font-weight-bold">{{ numFormat(user.credito_disponivel) }}</span>
            <div style="border-top: 1px solid #DDDDDD; width: 95%; margin: 4px auto"></div>
            <span class="col-9 font-weight-bold">Crédito utilizado</span>
            <span class="col-3 text-right font-weight-bold">{{ numFormat(user.credito_utilizado) }}</span>
        </div>
        <div v-if="!notAdd" class="d-flex flex-row align-items-center mt-2">
            <b-form-input v-model="value" type="number" placeholder="Adicionar Crédito" />
            <div v-if="loading" style="margin-left: 8px">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
            <div v-else class="btn-add">
                <i @click="addCredito" class="material-icons">add</i>
            </div>
        </div>
    </div>
</template>

<style lang="stylus" scoped>

.btn-add
    background-color #28A745
    color white
    display flex
    justify-content center
    align-items center
    padding 8px
    border-radius 8px
    margin-left 8px
    cursor pointer
    transition .2s
    
    &:hover
        background-color darken(#28A745, 10%)

</style>

<script>
import api from "@/services";
import { toast, errorFormater, dateFormat } from "@/utils";

export default {
  props: ["user", 'notAdd'],
  data: () => ({
    value: '',
    extrato: [],
    loading: false,
    loadingExtrato: true
  }),
  created() {
    api.post('/user/extrato', { user_id: this.user.id }).then(
        res => { 
            this.extrato = res.data || []
            this.loadingExtrato = false
        },
        error => {
            toast(this, "danger", "Erro", errorFormater(error), JSON.stringify(error.response.data));
            this.loading = false;
            return Promise.reject(error);
        }
    )
  },
  methods: {
    dateFormat: date => dateFormat(date).split('-')[0].trim(),
    addCredito() {
        this.loading = true
        // api
        api.post("/user/adicionarCredito", { user_id: this.user.id, value: this.value }).then(
            response => {
                const u = this.$store.state.redirectors.redirectors.find(u => u.id === this.user.id)
                u['extra_fields'] = u['extra_fields'].map(e => e.key === 'credito_disponivel' ? {...e, value: response.data.value } : e)
                this.$store.dispatch("redirectors/edit", u);
                this.loading = false;
                this.close();
                toast(this, "success", "Sucesso", "Crédito adicionado com sucesso");
                
            },
            error => {
                toast(this, "danger", "Erro", errorFormater(error), JSON.stringify(error.response.data));
                this.loading = false;
                return Promise.reject(error);
            }
      );
    },
    close() {
      this.$bvModal.hide("credito");
    },
    numFormat: n => (n * 1).toFixed(2) // (n + '$').replace(/\d(?=(\d{3})+\$)/g, '$&.').replace('$', '')
  },
};
</script>
