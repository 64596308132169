import { render, staticRenderFns } from "./Remessa.vue?vue&type=template&id=519996bc&scoped=true&"
import script from "./Remessa.vue?vue&type=script&lang=js&"
export * from "./Remessa.vue?vue&type=script&lang=js&"
import style0 from "./Remessa.vue?vue&type=style&index=0&id=519996bc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519996bc",
  null
  
)

export default component.exports