//import config from "../../../config";

export default {
  namespaced: true,
  state: {
    title: "Redirecionadores",
    itemsKey: "redirectors",
    createPacket: true,
    hasExtraFields: true,
    groupName: "redirector",
    forms: {
      base: [
        { key: "name", label: "Nome completo", type: "text", col: "5" },
        {
          key: "telephone",
          label: "Telefone",
          type: "text",
          col: "3",
          mask: [
            "+# (###) ##-##-##",
            "+## (##) #####-####",
            "+## (##) ####-####",
            "+# ###-###-####",
          ],
        },
        { key: "email", label: "Email", type: "text", col: "4" },
        {
          key: "cpf",
          label: "CPF/CNPJ",
          type: "text",
          col: "3",
          mask: ["###.###.###-##", "##.###.###/####-##"],
        },
        /*{
          key: config.dev
            ? "c9177ce6-f667-47e4-9b5e-7b8432bc4715"
            : "a2dea6fb-3e33-4a3e-83c4-b85a67dce8ad",
          label: "País de origem",
          type: "select",
          col: "3",
          options: [
            {
              value: "Brasil",
              text: "Brasil",
            },
            {
              value: "Estados Unidos",
              text: "Estados Unidos",
            },
            {
              value: "México",
              text: "México",
            },
          ],
        }*/
      ],
    },
  },
};
