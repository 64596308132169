<template>
  <Interface
    :title="state.title"
    :btnLabel="state.name"
    btnIcon="add"
    :btnLinkTo="{ name: state.title + '-Add' }"
  >
    <div class="mb-3">
      <b-row cols="2">
        <b-col
          v-for="item in items"
          :key="item.id"
          class="card-column"
          cols="6"
        >
          <b-card class="card">
            <b-card-title>
              {{ item.key }}
              <div class="btn-action-danger"></div>
              <div class="btn-action-warning"></div>
            </b-card-title>
            <b-card-text>
              {{ item.description }}
            </b-card-text>

            <div class="btn-show">Ver Mais</div>

            <b-card-text> y hours ago </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="md"
        align="right"
        class="my-0"
      ></b-pagination>
    </div>
  </Interface>
</template>

<style lang="stylus">

@import '../../style/main/colors.styl';
@import '../../style/main/mixins.styl';

.card
    background-color content-base-theme()

.card-title
    color #4472C4
    border-bottom 1px dashed #25292d
    padding-bottom 15px
    margin-bottom 15px
    min-height 60px
    vertical-align middle

.card-body p
    text-overflow: ellipsis

.card-column
    padding 10px 15px

.btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

btn-action()
    float left
    border-radius 50%
    width 40px
    height 40px
    margin 4px
    cursor pointer

.btn-action-warning
    btn-action()
    background-color yellow

.btn-action-danger
    btn-action()
    background-color red
</style>

<script>
import Interface from "@/components/Dashboard/Interface";

export default {
  components: { Interface },
  data: () => ({
    totalRows: 1,
    currentPage: 1,
    perPage: 8,
    isBusy: true,
    sortBy: "",
    isFiltering: false,
    filterData: {},
  }),
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  computed: {
    state() {
      return this.$store.state[this.module];
    },
    items() {
      return this.state[this.state.itemsKey];
    },
  },
  updated() {
    this.isBusy = false;
    // this.totalRows = this.items.length
  },
  methods: {},
};
</script>
