//import config from "@/config";

export default {
  namespaced: true,
  state: {
    title: "Remententes",
    itemsKey: "senders",
    externalModule: "senders",
    btnLabel: "Próximo",
    linkTo: "sendbox.receiver",
    hasEdit: true,
    createPacket: true,
    radios: true,
    groupName: "sender",
    // API Get index
    get: {
      //urn: "user/index?group_id=c316a0c3-7d83-4fab-9638-6dd9704a7cb9",
      urn: 'user/index?group_key=["Sender"]',
      response: "users",
    },
    hasExtraField: true,
    fields: [
      { key: "name", label: "Nome" },
      { key: "email", label: "Email" },
      { key: "telephone", label: "Telefone" },
    ],
  },
  forms: {},
};
