var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.add}},[_vm._v("Adicionar NCM")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveInfo}},[_vm._v("Salvar Alterações")])],1),_c('b-table',{staticStyle:{"padding-bottom":"16px"},attrs:{"sticky-header":"100vh","items":_vm.items,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"show-empty":"","busy":_vm.isLoading},scopedSlots:_vm._u([{key:"cell()",fn:function(ref){
var value = ref.value;
var item = ref.item;
var key = ref.field.key;
return [(_vm.edit != item.id)?[_vm._v(_vm._s(value))]:[_c('b-form-input',{attrs:{"list":'datalist-' + key},model:{value:(item[key]),callback:function ($$v) {_vm.$set(item, key, $$v)},expression:"item[key]"}}),_c('datalist',{attrs:{"id":'datalist-' + key}},_vm._l((_vm.items.map(function (i) { return i[key]; }).filter(function (v, i, self) { return self.indexOf(v) === i && v != item[key]; })),function(v){return _c('option',{key:'option-' + key + '-' + v},[_vm._v(_vm._s(v))])}),0)]]}},{key:"cell(actions)",fn:function(ref){
var id = ref.item.id;
var item = ref.item;
return [_c('div',{staticClass:"btn-icons btn-actions"},[(_vm.edit !== id)?_c('i',{staticClass:"material-icons btn-icon",on:{"click":function($event){return _vm.onEdit(id)}}},[_vm._v("edit")]):_c('i',{staticClass:"material-icons btn-icon",on:{"click":function($event){return _vm.onSave(item, id)}}},[_vm._v("check")]),_c('i',{staticClass:"material-icons btn-icon red",staticStyle:{"background-color":"red"},on:{"click":function($event){return _vm.onDelete(id)}}},[_vm._v("delete")])])]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('strong',[_vm._v("Nenhum Registro")])])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true}])}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"size":"md","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }