<template>
  <div>
    <span>Redirecionadores: </span>
    <Tree :nodes="tree" :root="true"></Tree>
    <div @click="close" class="btn-ok">Ok</div>
  </div>
</template>

<style lang="stylus" scoped>

h1
span
    text-align center
    font-weight bold

h1
    color #4CB6D2
    margin 8px 0 24px 0

span
    font-size 1.6em
    display block

.msg
    text-align left
    padding 8px 16px
    font-weight normal
    font-size 0.8em

.btn-ok
    background-color #4472C4
    color white
    text-align center
    padding 12px 48px
    border-radius 12px
    transition 0.3s
    width: 27%
    display block
    margin 16px auto 0
    cursor pointer

    &:hover
        color white
        background-color darken(#4472C4, 20%)
</style>

<script>
import Tree from "@/components/Tree";

export default {
  props: ["user"],
  components: { Tree },
  computed: {
    parents() {
      let parents = [];
      let id = this.user.tenant_id;

      while (id !== undefined) {
        let p = this.$store.state.redirectors.redirectors.find(
          (u) => u.id === id
        );
        parents.push(p?.name || "Super Admin");
        id = p?.tenant_id;
        // console.log(p?.tenant_id);
      }
      return parents;
    },
    childrens() {
      let f = (user, bold = false) => {
        let c = this.$store.state.redirectors.redirectors.filter(
          (u) => u.tenant_id === user.id
        );

        return {
          ...(c.length
            ? { name: user.name, children: c.map(f) }
            : { name: user.name }),
          bold: bold === true ? true : false,
        };
      };

      return f(this.user, true);
    },
    tree() {
      return this.parents.reduce(
        (t, u) => [{ name: u, children: t }],
        [this.childrens]
      );
    },
  },
  methods: {
    close() {
      this.$bvModal.hide("redirectors");
    },
  },
};
</script>
