<template>
  <div :class="root ? '' : 'node'">
    <div v-for="node in nodes" :key="node.name + '-key'">
      <span :class="(root ? '' : 'name') + (node.bold ? ' bold' : '')">{{
        node.name
      }}</span>
      <tree v-if="node.children" :nodes="node.children"></tree>
    </div>
  </div>
</template>

<style lang="stylus" scoped>

@import '../style/main/colors.styl'

span
    font-size 1.1em
    padding 4px 8px
    border-radius 8px
    cursor pointer

    &:hover
        background-color light

.bold
    font-weight bold

.node
    margin-left 40px
    margin-top 5px

.name
    position relative

    &:before
        position: absolute
        left: -20px
        top: -3px
        content: ''
        display: block
        border-left: 1px solid #000
        height: 18px
        border-bottom: 1px solid #000
        width: 20px
</style>

<script>
export default {
  name: "tree",
  props: ["nodes", "root"],
};
</script>
