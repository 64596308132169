<template>
  <div class="main">
    <b-form @submit="onSubmit" ref="form">
      <!--Nome; Email; CPF; Telefone; Número; Endereço; Complemento; Cidade; UF; CEP; Pais-->

      <!-- Nome -->
      <b-form-group id="input-add-group-name">
        <div class="h-separator">
          <label for="input-add-name" class="title">Nome *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-name"
          v-model="form.name"
          type="text"
          placeholder="Nome"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Email -->
      <b-form-group id="input-add-group-email">
        <div class="h-separator">
          <label for="input-add-email" class="title">Email *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-email"
          v-model="form.email"
          type="text"
          placeholder="Email"
          required
        ></b-form-input>
      </b-form-group>

      <!-- CPF -->
      <b-form-group id="input-add-group-cpf">
        <div class="h-separator">
          <label for="input-add-cpf" class="title">CPF *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-cpf"
          v-model="form.cpf"
          type="text"
          placeholder="CPF"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Telefone -->
      <b-form-group id="input-add-group-telefone">
        <div class="h-separator">
          <label for="input-add-telefone" class="title">Telefone *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-telefone"
          v-model="form.telephone"
          type="text"
          placeholder="Telefone"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Número -->
      <b-form-group id="input-add-group-numero">
        <div class="h-separator">
          <label for="input-add-numero" class="title">Número *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-numero"
          v-model="form.numero"
          type="text"
          placeholder="Número"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Endereço -->
      <b-form-group id="input-add-group-endereco">
        <div class="h-separator">
          <label for="input-add-endereco" class="title">Endereço *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-endereco"
          v-model="form.endereco"
          type="text"
          placeholder="Endereço"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Complemento -->
      <b-form-group id="input-add-group-complemento">
        <div class="h-separator">
          <label for="input-add-complemento" class="title">Complemento</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-complemento"
          v-model="form.complemento"
          type="text"
          placeholder="Complemento"
        ></b-form-input>
      </b-form-group>

      <!-- Cidade -->
      <b-form-group id="input-add-group-complemento">
        <div class="h-separator">
          <label for="input-add-complemento" class="title">Cidade *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-cidade"
          v-model="form.cidade"
          type="text"
          placeholder="Cidade"
          required
        ></b-form-input>
      </b-form-group>

      <!-- UF -->
      <b-form-group id="input-add-group-complemento">
        <div class="h-separator">
          <label for="input-add-complemento" class="title">UF *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-uf"
          v-model="form.uf"
          type="text"
          placeholder="UF"
          required
        ></b-form-input>
      </b-form-group>

      <!-- CEP -->
      <b-form-group id="input-add-group-complemento">
        <div class="h-separator">
          <label for="input-add-complemento" class="title">CEP *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-cep"
          v-model="form.cep"
          type="text"
          placeholder="CEP"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Pais -->
      <b-form-group id="input-add-group-pais">
        <div class="h-separator">
          <label for="input-add-pais" class="title">Pais *</label>
          <span></span>
        </div>

        <b-form-input
          id="input-add-pais"
          v-model="form.pais"
          type="text"
          placeholder="Pais"
          required
        ></b-form-input>
      </b-form-group>

      <div class="d-flex flex-column align-items-end">
        <b-button class="btnsave" type="submit" variant="primary"
          >Salvar</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "@/services";
import { toast, errorFormater, userToExtraFields } from "@/utils";

export default {
  data: () => ({
    form: {},
  }),
  created() {
    this.form = userToExtraFields(this.$store.state.user);
    
    if(this.form.zip) this.form = { ...this.form, cep: this.form.zip }
    else if(this.form.cep) this.form = { ...this.form, zip: this.form.cep }

    if(this.form.complemento === '&') this.form = { ...this.form, complemento: '' }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.form = { ...this.form, zip: this.form.cep }

      api.post("auth/update/" + this.$store.state.user.id, { ...this.form, complemento: this.form.complemento || '&' } ).then(
        (response) => {
          // console.log(response);
          if ([200, 201, 204].includes(response.status)) {
            // this.$store.dispatch;

            toast(
              this,
              "success",
              "Dados atualizado com sucesso",
              "Dados atualizado com sucesso"
            );

            this.$bvModal.hide("edit-user");
            this.$store.dispatch("login", {
              user: response.data.user,
              token: this.$store.state.token,
            });
          }
        },
        (error) => {
          toast(this, "danger", "Erro", errorFormater(error));
          return Promise.reject(error);
        }
      );
    },
  },
};
</script>

<style lang="stylus">
@import '../../style/main/colors.styl'

.h-separator
    display flex

    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary

    span
        align-self center

    span:last-child
        border 1px dashed primary
        height 1px

    span:last-child
        flex-grow 1

.btnsave
    width 100px
    background-color #4472C4
    font-size 14px
</style>
